export function LoadingSpinner({ label, inline = false }) {
    if (!inline)
        return (
            <div id="spinner-overlay">
                <div className="spinner">
                    <div className="lrect1"></div>
                    <div className="lrect2"></div>
                    <div className="lrect3"></div>
                    <div className="lrect4"></div>
                    <div className="lrect5"></div>
                </div>
                {label && <p className="spinner-label h3">{label}</p>}
            </div>
        );

    return (
        <div className="spinner-inline-wrapper">
            <div className="spinner">
                <div className="lrect1"></div>
                <div className="lrect2"></div>
                <div className="lrect3"></div>
                <div className="lrect4"></div>
                <div className="lrect5"></div>
            </div>
            {label && <p className="spinner-label h3">{label}</p>}
        </div>
    );
}
